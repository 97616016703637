import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/cp1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/wr1.png";

export default function WaitingReview() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      

      <h1 className="browserTitle">Waiting Review</h1>
      <ol className="listTop">
        <li>Sign in a go to your Dashboard.</li>
        <img src={img1} alt="not found" width="80%" height="80%" />
        <br />
        <Divider />
        <li>
          To time stamp when the patient is waiting review click the “Review”
          button
        </li>
        <img src={img2} alt="not found" width="90%" height="90%" />
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Waiting Review</h1>
      <ol className="listTop">
        <li>Sign in a go to your Dashboard.</li>
        <img src={img1} alt="not found" width="80%" height="80%" />
        <br />
        <Divider />
        <li>
          To time stamp when the patient is waiting review click the “Review”
          button
        </li>
        <img src={img2} alt="not found" width="90%" height="90%" />
      </ol>
    </MobileView>
    </>
  
  );
}
