import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/rnp1.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/rep1.png";
const img4 = "https://mspublicaccess.blob.core.windows.net/faqimages/rep2.png";
const img5 = "https://mspublicaccess.blob.core.windows.net/faqimages/rnp4.jpeg";
const img6 = "https://mspublicaccess.blob.core.windows.net/faqimages/rnp5.png";
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/rnp6.png";

export default function RegisterExistingPatient() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      <h1 className="browserTitle">Register Existing Patient</h1>
      <ol className="listTop">
        <li>Click on the red “Register Patient” button on the Dashboard:</li>
        <img src={img2} alt="not found" width="80%" />
        <br />
        <Divider />
        <li>
          Enter the Patients Name and their appointment type, then click on the
          “Register Existing Patient” button and click on the patient’s
          insurance provider:
        </li>
        <img src={img3} alt="not found" width="80%" />
        <img src={img4} alt="not found" />
        <li>Enter the patient’s mobile number</li>
        <img src={img6} alt="not found" />
        <li>Have the patient fill out the form on their phone:</li>
        <img src={img5} alt="not found" width="35%" />
        <li>
          When the user finishes the registration they will appear in the
          dashboard:
        </li>
        <img src={img1} alt="not found" width="80%" />
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Register Existing Patient</h1>
      <ol className="listTop">
        <li>Click on the red “Register Patient” button on the Dashboard:</li>
        <img src={img2} alt="not found" width="80%" />
        <br />
        <Divider />
        <li>
          Enter the Patients Name and their appointment type, then click on the
          “Register Existing Patient” button and click on the patient’s
          insurance provider:
        </li>
        <img src={img3} alt="not found" width="80%" />
        <img src={img4} alt="not found" />
        <li>Enter the patient’s mobile number</li>
        <img src={img6} alt="not found" />
        <li>Have the patient fill out the form on their phone:</li>
        <img src={img5} alt="not found" width="35%" />
        <li>
          When the user finishes the registration they will appear in the
          dashboard:
        </li>
        <img src={img1} alt="not found" width="80%" />
      </ol>
    </MobileView>
    </>

  );
}
