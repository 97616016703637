import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserView, MobileView } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  sectionHead: {
    backgroundImage: 'linear-gradient(45deg, #42b0f5 30%, #1b1d4d 90%)',
    color: "white",
    padding: "40px",
    borderRadius: 5,
   
  },
  fieldContainer: {
    marginTop: "-8px",
    backgroundColor: "white",
    color: "black",
    borderColor: "#1b1d4d",
    borderStyle: "solid",
    borderRadius: 5,
  },
}));

export default function Test() {
  const classes = useStyles();
  return (
    <>
    <BrowserView>
    <div className="center">
        <h2 className={classes.sectionHead}>Welcome to the FAQ</h2>
        {/* <p className={classes.fieldContainer}>
          One stop for all your questions
        </p> */}
      </div>
    </BrowserView>
    <MobileView>
    <div className="mobileLandingTitle">
        <h2>Welcome to the FAQ</h2>
        <h3>swipe right for menu</h3>
        {/* <p className={classes.fieldContainer}>
          One stop for all your questions
        </p> */}
      </div>
    </MobileView>
    </>
  );
}
