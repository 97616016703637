import React from "react";
import Divider from "@material-ui/core/Divider";
import SearchSection from "./components/search";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/sd1.png";

export default function ScanDocs() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      <h1 className="browserTitle">Scan Documents</h1>
      <ol className="listTop">
        <SearchSection />
        <br />
        <Divider />
        <li>Click on the “Patient Files” or “Scan Documents” button:</li>
        <img src={img2} alt="not found" width="80%" height="80%" />
        <li>
          Scan the file to your computer or network drive and select it to be
          uploaded.
        </li>
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Scan Documents</h1>
      <ol className="listTop">
        <SearchSection />
        <br />
        <Divider />
        <li>Click on the “Patient Files” or “Scan Documents” button:</li>
        <img src={img2} alt="not found" width="80%" height="80%" />
        <li>
          Scan the file to your computer or network drive and select it to be
          uploaded.
        </li>
      </ol>
    </MobileView>
    </>
 
  );
}
