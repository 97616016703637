import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/Cnp1.png";

export default function CreateNewPatient() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      
      <h1 className="browserTitle">Create New Patient</h1>
      <ol className="listTop">
        <li>
          Go to your dashboard by logging into My Medical and click on the “New
          Patient” button
          {/* <ol type="a">
            <li> Use the search bar on the main screen </li>
            <li> Or Use the search bar on the dashboard</li>
            <li> Scroll through the patients list on the side menu</li>
          </ol> */}
        </li>
        <img src={img1} alt="not found" width="90%" height="90%" />
        <li>
          Fill in the patients details in full. n.b. The Emergency Contact
          information is very important to capture from the patient.
        </li>

        <li>
          Scroll to the bottom of the page and click "Save & Register", “Save” or "Cancel", "Save" will add
          the patient to the database, "Save and Register" will add them to the dashboard.
        </li>
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Create New Patient</h1>
      <ol className="listTop">
        <li>
          Go to your dashboard by logging into My Medical and click on the “New
          Patient” button
          {/* <ol type="a">
            <li> Use the search bar on the main screen </li>
            <li> Or Use the search bar on the dashboard</li>
            <li> Scroll through the patients list on the side menu</li>
          </ol> */}
        </li>
        <img src={img1} alt="not found" width="90%" height="90%" />
        <li>
          Fill in the patients details in full. n.b. The Emergency Contact
          information is very important to capture from the patient.
        </li>

        <li>
          Scroll to the bottom of the page and click "Save & Register", “Save” or "Cancel", "Save" will add
          the patient to the database, "Save and Register" will add them to the dashboard.
        </li>
      </ol>
    </MobileView>
    </>
    
  );
}
