import React from "react";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

export default function DoctorsList() {
  return (
    <>
      <h2 data-toggle="collapse" data-target="#admin" className="navhead">
        Admin
      </h2>
      <Divider />
      <div class="collapse indent" id="admin">
        {/* Patient MGMT */}
        <h3 data-toggle="collapse" data-target="#AdminpatientMgmt">
          Patient Management
        </h3>
        <Divider />
        <ul id="AdminpatientMgmt" class="collapse">
          <Link to="/MergePatient">
              <li>Merge Patient</li>
          </Link>
          <Link to="/AddReferraltoPatient">
            <li>Add Referral</li>
          </Link>
          <Link to="/AddPatientFiles">
            <li>Add Patient Files</li>
          </Link>
          <Link to="/AddGeneralNotes">
            <li>Add General Notes</li>
          </Link>
          <Link to="/ViewPatientForms">
            <li>View Patient Forms</li>
          </Link>
          <Link to="/ViewAddress">
            <li>View Address</li>
          </Link>
          <Link to="/ViewAddClinicNts">
            <li>Clinical Notes</li>
          </Link>
          <Link to="/CancelPatients">
            <li>Cancel Patients</li>
          </Link>
          <Link to="/ScanDocs">
            <li>Scan Documents</li>
          </Link>

          <Link to="/WaitingReview">
            <li>Waiting Review</li>
          </Link>
          <Link to="/AdmitPatient">
            <li>Admit Patient</li>
          </Link>
        </ul>
        {/* Calendar */}
        <h3 data-toggle="collapse" data-target="#admin2">
          Calendar
        </h3>
        <Divider />
        <ul id="admin2" class="collapse">
          <Link to="/ViewAllAppointments">
            <li>View All Appointments</li>
          </Link>
          <Link to="/ViewDocAppointments">
            <li>View Other Doctor's Appointments</li>
          </Link>
          <Link to="/ViewDocCalendars">
            <li>View Doctor's Calendars</li>
          </Link>
        </ul>
        {/* Misc */}
        <h3 data-toggle="collapse" data-target="#admin3">
          Misc
        </h3>
        <Divider />
        <ul id="admin3" class="collapse">
          <Link to="/ViewReports">
            <li>View Reports</li>
          </Link>
          <Link to="/ViewDashboard">
            <li>View Dashboards</li>
          </Link>
          <Link to="/SearchPatients">
            <li>Search Patients</li>
          </Link>
          <Link to="/ViewPolicies">
            <li>View Policies and procedures</li>
          </Link>
        </ul>
        {/* Administration  */}
        <h3 data-toggle="collapse" data-target="#admin4">
          Administration
        </h3>
        <Divider />
        <div id="admin4" class="collapse indent">
          <h4 data-toggle="collapse" data-target="#admin5">
            Staff Administration
          </h4>
          <Divider />
          <ul id="admin5" class="collapse">
            <Link to="/CreateNewStaff">
              <li>Create New Staff</li>
            </Link>
            <Link to="/RosterTime">
              <li>Roster Time</li>
            </Link>
            <Link to="/EditStaffInfo">
              <li>Edit Staff Info</li>
            </Link>
            <Link to="/StaffHolidays">
              <li>Staff Holidays</li>
            </Link>
          </ul>
          <h4 data-toggle="collapse" data-target="#admin6">
            Patient Administration
          </h4>
          <Divider />
          <ul id="admin6" class="collapse">
            <Link to="/EditPatientTypes">
              <li>Edit Patient Types</li>
            </Link>
          </ul>
          <h4 data-toggle="collapse" data-target="#admin7">
            Template Administration
          </h4>
          <Divider />
          <ul id="admin7" class="collapse">
            <Link to="/EditEmailTemplates">
              <li>Edit Email Templates</li>
            </Link>
            <Link to="/EditReferralTemplates">
              <li>Edit/Add Referral Templates</li>
            </Link>
          </ul>
          <h4 data-toggle="collapse" data-target="#admin8">
            Treatment Administration
          </h4>
          <Divider />
          <ul id="admin8" class="collapse">
            <Link to="/EditTreatments">
              <li>Add/Edit Treatments</li>
            </Link>
          </ul>
          <h4 data-toggle="collapse" data-target="#admin9">
            Appointment Administration
          </h4>
          <Divider />
          <ul id="admin9" class="collapse">
            <Link to="/EditAppointmentTypes">
              <li>Add/Edit Appointment Types</li>
            </Link>
          </ul>
          <h4 data-toggle="collapse" data-target="#admin19">
            Access Control
          </h4>
          <ul id="admin19" class="collapse">
            <Link to="/AccessControl">
              <li>Access Control</li>
            </Link>
          </ul>

          <Divider />
        </div>
      </div>
    </>
  );
}
