import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/vr1.png";

export default function ViewReports() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      
      <h1 className="browserTitle">View Reports</h1>
      <ol className="listTop">
        <li>Click on the “Reports” tab on the navigation menu</li>
        <img src={img1} alt="not found" />
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">View Reports</h1>
      <ol className="listTop">
        <li>Click on the “Reports” tab on the navigation menu</li>
        <img src={img1} alt="not found" />
      </ol>
    </MobileView>
    </>
   
  );
}
