import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/sh1.png";

export default function StaffHolidays() {
  return ( <>
    <BrowserView>
    <div className="spacebottom">
      <h1 className="browserTitle">Staff Holidays</h1>
      <ol className="listTop">
        <li>
          On the navigation menu on the left-hand side of the page, click on the
          “Admin Panel” dropdown and select “Add New Holiday”
        </li>

        <img src={img1} alt="not found" width="60%" height="60%" />

        <br />
        <Divider />

        <li>Fill in the Holiday name and date and hit “Save”</li>
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Staff Holidays</h1>
      <ol className="listTop">
        <li>
          On the navigation menu on the left-hand side of the page, click on the
          “Admin Panel” dropdown and select “Add New Holiday”
        </li>

        <img src={img1} alt="not found" width="60%" height="60%" />

        <br />
        <Divider />

        <li>Fill in the Holiday name and date and hit “Save”</li>
      </ol>
    </MobileView>
    </>

 
  );
}

