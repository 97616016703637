import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/esi1.png";

export default function EditStaffInfo() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Edit Staff Info</h1>
          <ol className="listTop">
            <li>
              On the navigation menu on the left-hand side of the page, click on
              the “Admin Panel” dropdown and select “Staff”
            </li>

            <br />
            <Divider />

            <li>Click the “Edit” button</li>
            <img src={img1} alt="not found" width="80%" height="80%" />
            <li>
              Make your changes and then click “Save” at the bottom of the page
            </li>
          </ol>
        </div>
      </BrowserView>
      <MobileView>
        <h1 className="mobileTitle">Edit Staff Info</h1>
        <ol className="listTop">
          <li>
            On the navigation menu on the left-hand side of the page, click on
            the “Admin Panel” dropdown and select “Staff”
          </li>

          <br />
          <Divider />

          <li>Click the “Edit” button</li>
          <img src={img1} alt="not found" width="80%" height="80%" />
          <li>
            Make your changes and then click “Save” at the bottom of the page
          </li>
        </ol>
      </MobileView>
    </>
  );
}
