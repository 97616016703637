import React from "react";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

export default function DoctorsList() {
  return (
    <>
      <h2 data-toggle="collapse" data-target="#recpt" className="navhead">
        Reception
      </h2>
      <Divider />
      <div class="collapse indent" id="recpt">
        <Link className="video" to="/BillingAndPaymentsVideo">
          <h3 data-toggle="collapse">Videos</h3>
        </Link>
        <Divider />
        {/* Patient MGMT */}
        <h3 data-toggle="collapse" data-target="#recptpatientMgmt">
          Patient Management
        </h3>
        <Divider />
        <ul id="recptpatientMgmt" class="collapse">
        <Link to="/MergePatient">
            <li>Merge Patient</li>
          </Link>
          <Link to="/CreateNewPatient">
            <li>Create New Patient</li>
          </Link>
          <Link to="/CreateAppointment">
            <li>Create Appointment</li>
          </Link>
          <Link to="/RegisterNewPatient">
            <li>Register New Patient</li>
          </Link>
          <Link to="/RegisterExistingPatient">
            <li>Register Existing Patient</li>
          </Link>
          <Link to="/EditPatientInfo">
            <li>Edit Patient Info</li>
          </Link>
          <Link to="/AddReferraltoPatient">
            <li>Add Referral</li>
          </Link>
          <Link to="/AddPatientFiles">
            <li>Add Patient Files</li>
          </Link>
          <Link to="/AddGeneralNotes">
            <li>Add General Notes</li>
          </Link>
          <Link to="/ViewPatientForms">
            <li>View Patient Forms</li>
          </Link>
          <Link to="/ViewAddress">
            <li>View Address</li>
          </Link>
          <Link to="/ViewAddClinicNts">
            <li>Clinical Notes</li>
          </Link>
          <Link to="/CancelPatients">
            <li>Cancel Patients</li>
          </Link>
          <Link to="/ScanDocs">
            <li>Scan Documents</li>
          </Link>

          <Link to="/WaitingReview">
            <li>Waiting Review</li>
          </Link>
          <Link to="/AdmitPatient">
            <li>Admit Patient</li>
          </Link>
        </ul>
        {/* Billing And Payments */}
        <h3 data-toggle="collapse" data-target="#recpt4">
          Billing
        </h3>
        <Divider />
        <ul id="recpt4" class="collapse">
          <Link to="/BillingAndPayments">
            <li>Billing And Payments</li>
          </Link>
          <Link to="/Receipts">
            <li>Receipts</li>
          </Link>
        </ul>
        {/* Calendar */}
        <h3 data-toggle="collapse" data-target="#recpt1">
          Calendar
        </h3>
        <Divider />
        <ul id="recpt1" class="collapse">
          <Link to="/ViewAllAppointments">
            <li>View All Appointments</li>
          </Link>
          <Link to="/ViewDocAppointments">
            <li>View Other Doctor's Appointments</li>
          </Link>
          <Link to="/ViewDocCalendars">
            <li>View Doctor's Calendars</li>
          </Link>
        </ul>
        {/* Misc */}
        <h3 data-toggle="collapse" data-target="#recpt3">
          Misc
        </h3>
        <Divider />
        <ul id="recpt3" class="collapse">
          <Link to="/ViewReports">
            <li>View Reports</li>
          </Link>
          <Link to="/ViewDashboard">
            <li>View Dashboards</li>
          </Link>
          <Link to="/SearchPatients">
            <li>Search Patients</li>
          </Link>
          <Link to="/ViewPolicies">
            <li>View Policies and procedures</li>
          </Link>
        </ul>
      </div>
    </>
  );
}
