import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/eat1.png";

export default function EditAppointmentTypes() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      <h1 className="browserTitle">Add/Edit Appointment Types</h1>
      <ol className="listTop">
        <li>On the navigation menu on the left-hand side of the page, click on the “Admin Panel” dropdown and select “Appointment Type”</li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />

        <li>You can edit existing Appointment Types by clicking on the highlighted edit button shown above.</li>

        <li>Or you can add a new Appointment Type by clicking the green “Add Appointment Type” button on the top right. </li>
        <li>You can also view or delete the Appointment Types using the corresponding buttons too.</li>

       
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Add/Edit Appointment Types</h1>
      <ol className="listTop">
        <li>On the navigation menu on the left-hand side of the page, click on the “Admin Panel” dropdown and select “Appointment Type”</li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />

        <li>You can edit existing Appointment Types by clicking on the highlighted edit button shown above.</li>

        <li>Or you can add a new Appointment Type by clicking the green “Add Appointment Type” button on the top right. </li>
        <li>You can also view or delete the Appointment Types using the corresponding buttons too.</li>

       
      </ol>
    </MobileView>
    </>
  
  );
}