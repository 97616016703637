import React from "react";
import Divider from "@material-ui/core/Divider";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/ad1.png";
const img2 =
  "https://mspublicaccess.blob.core.windows.net/faqimages/assign1.png";

export default function AssignDoctor() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Assign Doctor</h1>

          <ol className="listTop">
            <li>
              Click on the patients name in the dashboard and enter your pin
            </li>
            <img src={img1} alt="not found" width="80%" height="80%" />
            <br />
            <Divider />
            <li>This will then set you as the doctor for this patient:</li>
            <img src={img2} alt="not found" width="80%" height="80%" />
          </ol>
        </div>
      </BrowserView>

      <MobileView>
        <h1 className="mobileTitle">Assign Doctor</h1>

        <ol className="listTop">
          <li>
            Click on the patients name in the dashboard and enter your pin
          </li>
          <img src={img1} alt="not found" width="80%" height="80%" />
          <br />
          <Divider />
          <li>This will then set you as the doctor for this patient:</li>
          <img src={img2} alt="not found" width="80%" height="80%" />
        </ol>
      </MobileView>
    </>
  );
}
