import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/vpp1.png";

export default function ViewPolicies() {
  return ( <>
    <BrowserView>
    <div className="spacebottom">
      
      <h1 className="browserTitle">View Policies and Procedures</h1>
      <ol className="listTop">
        <li>
          Click on the “Policies and Procedures” tab on the navigation menu
        </li>
        <img src={img1} alt="not found" />
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">View Policies and Procedures</h1>
      <ol className="listTop">
        <li>
          Click on the “Policies and Procedures” tab on the navigation menu
        </li>
        <img src={img1} alt="not found" />
      </ol>
    </MobileView>
    </>

    
  );
}
