import React from "react";
import Divider from "@material-ui/core/Divider";
import SearchSection from "./components/search";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/va1.png";

export default function ViewAddress() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      <h1 className="browserTitle">View Patient Address</h1>
      <ol className="listTop">
        <SearchSection />
        <br />
        <Divider />
        <li>Click on the “Address” button:</li>
        <img src={img2} alt="not found" />
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">View Patient Address</h1>
      <ol className="listTop">
        <SearchSection />
        <br />
        <Divider />
        <li>Click on the “Address” button:</li>
        <img src={img2} alt="not found" />
      </ol>
    </MobileView>
    </>
   
  );
}
