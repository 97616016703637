import React from "react";
import Divider from "@material-ui/core/Divider";
import SearchSection from "./components/search";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/vcn1.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/vcn2.png";

export default function ViewAddClinicNts() {
  return ( <>
    <BrowserView>
    <div className="spacebottom">
     
     <h1 className="browserTitle">View/Add Clinic Notes</h1>
     <ol className="listTop">
       <SearchSection />
       <br/>
       <Divider />
       <li>Click on the patient to open their dashboard and click the “Clinical Notes” button:</li>
       <img src={img2} alt="not found" />
       <li>View all previous notes here, Add SOAP notes by clicking on the textbox
         - Or use your microphone to create notes by clicking 'Record with microphone' button. 
       </li>
       <img src={img3} alt="not found" width="90%" height="90%"/>
       <li>To stop the recording click the button again.</li>
     </ol>
   </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">View/Add Clinic Notes</h1>
     <ol className="listTop">
       <SearchSection />
       <br/>
       <Divider />
       <li>Click on the patient to open their dashboard and click the “Clinical Notes” button:</li>
       <img src={img2} alt="not found" />
       <li>View all previous notes here, Add SOAP notes by clicking on the textbox
         - Or use your microphone to create notes by clicking 'Record with microphone' button. 
       </li>
       <img src={img3} alt="not found" width="90%" height="90%"/>
       <li>To stop the recording click the button again.</li>
     </ol>
    </MobileView>
    </>

  
  );
}
