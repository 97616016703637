import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/ca1.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/ca2.png";
const img4 = "https://mspublicaccess.blob.core.windows.net/faqimages/ca3.png";
const img5 = "https://mspublicaccess.blob.core.windows.net/faqimages/ca5.png";
const img6 = "https://mspublicaccess.blob.core.windows.net/faqimages/ca4.png";

export default function CreateAppointment() {
  return (
    <>
    <BrowserView>
    
    <div className="spacebottom">
      <h1 className="browserTitle">Create Appointment</h1>
      <ol className="listTop">
        <li>Click on the red “My Calendar” button on the Dashboard:</li>
        <img src={img2} alt="not found" width="80%" />
        <br />
        <Divider />
        <li>
          Scroll on the calendar to a free section and click the time you wish
          to book the appointment:
        </li>
        <img src={img3} alt="not found" width="80%" />
        <li>
          Fill out the required information, Patient name, Doctor and
          Appointment type:
        </li>
        <img src={img4} alt="not found" width="80%"/>
        <img src={img5} alt="not found"/>
        <li>
          If you need to change this appointment you can click on reschedule and
          choose an new time slot (a) or delete the appointment (b)
        </li>
        <img src={img6} alt="not found" />
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Create Appointment</h1>
      <ol className="listTop">
        <li>Click on the red “My Calendar” button on the Dashboard:</li>
        <img src={img2} alt="not found" width="80%" />
        <br />
        <Divider />
        <li>
          Scroll on the calendar to a free section and click the time you wish
          to book the appointment:
        </li>
        <img src={img3} alt="not found" width="80%" />
        <li>
          Fill out the required information, Patient name, Doctor and
          Appointment type:
        </li>
        <img src={img4} alt="not found" width="80%"/>
        <img src={img5} alt="not found"/>
        <li>
          If you need to change this appointment you can click on reschedule and
          choose an new time slot (a) or delete the appointment (b)
        </li>
        <img src={img6} alt="not found" />
      </ol>
    </MobileView>
    </>
    
  );
}
