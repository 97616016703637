import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";



export default function BillingAndPaymentsVideo() {
  return (
    <>
      <BrowserView>
      <div className="spacebottom">
          <h1 className="browserTitle">Full Demo Video</h1>
          <div className="videoContainer">
            <LiteYouTubeEmbed
              id="hB_IC6oSZ0Y"
              title="MedoSync Billing"
            />
          </div>
        </div>
        <div className="spacebottom">
          <h1 className="browserTitle">Register Patients</h1>
          <div className="videoContainer">
            <LiteYouTubeEmbed
              id="xUip6-AGd4w"
              title="MedoSync Billing"
            />
          </div>
        </div>
        <div className="spacebottom">
          <h1 className="browserTitle">Create Appointments</h1>
          <div className="videoContainer">
          <LiteYouTubeEmbed
              id="9c2PpGG9QCY"
              title="MedoSync Billing"
            />
          </div>
        </div>
        <div className="spacebottom">
          <h1 className="browserTitle">Billing and Payments</h1>
          <div className="videoContainer">
            <LiteYouTubeEmbed
              id="mzleufIv17Y"
              title="MedoSync Billing"
            />
          </div>
        </div>
      </BrowserView>
      <MobileView>
      <div className="spacebottom">
          <h1 className="mobileTitle">Full Demo Video</h1>
          <div className="videoContainer">
            <LiteYouTubeEmbed
              id="hB_IC6oSZ0Y"
              title="MedoSync Billing"
            />
          </div>
        </div>
      <div className="spacebottom">
          <h1 className="mobileTitle">Register Patients</h1>
          <div className="videoContainer">
            <LiteYouTubeEmbed
              id="xUip6-AGd4w"
              title="MedoSync Billing"
            />
          </div>
        </div>
        <div className="spacebottom">
          <h1 className="mobileTitle">Create Appointments</h1>
          <div className="videoContainer">
          <LiteYouTubeEmbed
              id="9c2PpGG9QCY"
              title="MedoSync Billing"
            />
          </div>
        </div>
        <div className="spacebottom">
          <h1 className="mobileTitle">Billing and Payments</h1>
          <div className="videoContainer">
            <LiteYouTubeEmbed
              id="mzleufIv17Y"
              title="MedoSync Billing"
            />
          </div>
        </div>
      </MobileView>
    </>
  );
}
