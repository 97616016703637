import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/cp1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/cp2.png";

export default function CancelPatients() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Cancel/No-Show Patients</h1>
          <ol className="listTop">
            <li>Sign in a go to your Dashboard.</li>
            <img src={img1} alt="not found" width="80%" height="80%" />
            <br />
            <Divider />
            <li>
              If the patient has missed or has cancelled their appointment – you
              can update this by clicking the “More” button and selecting the
              option from the dropdown
            </li>
            <img src={img2} alt="not found" width="80%" height="80%" />
          </ol>
        </div>
      </BrowserView>
      <MobileView>
        <h1 className="mobileTitle">Cancel/No-Show Patients</h1>
        <ol className="listTop">
          <li>Sign in a go to your Dashboard.</li>
          <img src={img1} alt="not found" width="80%" height="80%" />
          <br />
          <Divider />
          <li>
            If the patient has missed or has cancelled their appointment – you
            can update this by clicking the “More” button and selecting the
            option from the dropdown
          </li>
          <img src={img2} alt="not found" width="80%" height="80%" />
        </ol>
      </MobileView>
    </>
  );
}
