import React from "react";

import "./App.css";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
//Landing
import Landing from "./pages/Landing";
//Patient management
import MergePatient from "./pages/MergePatient"
import CreateNewPatient from "./pages/CreateNewPatient";
import EditPatientInfo from "./pages/EditPatientInfo";
import AddReftoPatient from "./pages/AddReferraltoPatient";
import AddPatientFiles from "./pages/AddPatientFiles";
import ViewPatientForms from "./pages/ViewPatientForms";
import ViewAddress from "./pages/ViewAddress";
import ViewAddClinicNts from "./pages/ViewAddClinicNts";
import ScanDocs from "./pages/ScanDocs";
import AddGeneralNotes from "./pages/AddGeneralNotes";
import CancelPatients from "./pages/CancelPatients";
import RequestXRay from "./pages/RequestXRay";
import WaitingReview from "./pages/WaitingReview";
import AdmitPatient from "./pages/AdmitPatient";
import Discharge from "./pages/Discharge";
import Prescription from "./pages/Prescription";
import AssignDoctor from "./pages/AssignDoctor";
import CreateAppointment from "./pages/CreateAppointment";
import RegisterNewPatient from "./pages/RegisterNewPatient";
import RegisterExistingPatient from "./pages/RegisterExistingPatient";
import NotFound404 from "./pages/404";
import BillingAndPayments from "./pages/BillingAndPayments";
import Receipts from "./pages/Receipts";
//Calendar
import ViewAllAppointments from "./pages/ViewAllAppointments";
import ViewDocCalendars from "./pages/ViewDocCalendars";
import ViewDocAppointments from "./pages/ViewDocAppointments";
//Misc
import ViewReports from "./pages/ViewReports";
import ViewDashboard from "./pages/ViewDashboard";
import SearchPatients from "./pages/SearchPatients";
import ViewPolicies from "./pages/ViewPolicies";
import FaqNav from "./components/SideNav";
//Admin
// Staff Admin
import CreateNewStaff from "./pages/CreateNewStaff";
import RosterTime from "./pages/RosterTime";
import EditStaffInfo from "./pages/EditStaffInfo";
import StaffHolidays from "./pages/StaffHolidays";

//Patient Admin
import EditPatientTypes from "./pages/EditPatientTypes";
//Template Admin
import EditEmailTemplates from "./pages/EditEmailTemplates";
import EditReferralTemplates from "./pages/EditReferralTemplates";

//Treatment Admin
import EditTreatments from "./pages/EditTreatments";

//Appointment Admin
import EditAppointmentTypes from "./pages/EditAppointmentTypes";
//Access Control
import AccessControl from "./pages/AccessControl";

//Videos
import BillingAndPaymentsVideo from "./pages/BillingAndPaymentsVideo";
import DoctorVideo from "./pages/DoctorVideo";
import NurseVideo from "./pages/NurseVideo.js";

export default function App() {
  return (
    <>
      <Router>
        <FaqNav />

        <Switch>
          {/* Home */}
          <Route path="/" exact component={Landing} />

          {/* Videos */}
          <Route
            path="/BillingAndPaymentsVideo"
            component={BillingAndPaymentsVideo}
          />
          <Route path="/DoctorVideo" component={DoctorVideo} />
          <Route path="/NurseVideo" component={NurseVideo} />

          {/* Patient Management */}
          <Route path="/MergePatient" component={MergePatient} />
          <Route path="/CreateNewPatient" component={CreateNewPatient} />
          <Route path="/EditPatientInfo" component={EditPatientInfo} />
          <Route path="/AddReferraltoPatient" component={AddReftoPatient} />
          <Route path="/AddPatientFiles" component={AddPatientFiles} />
          <Route path="/ViewPatientForms" component={ViewPatientForms} />
          <Route path="/ViewAddress" component={ViewAddress} />
          <Route path="/ViewAddClinicNts" component={ViewAddClinicNts} />
          <Route path="/ScanDocs" component={ScanDocs} />
          <Route path="/AddGeneralNotes" component={AddGeneralNotes} />
          <Route path="/CancelPatients" component={CancelPatients} />
          <Route path="/RequestXRay" component={RequestXRay} />
          <Route path="/WaitingReview" component={WaitingReview} />
          <Route path="/AdmitPatient" component={AdmitPatient} />
          <Route path="/Discharge" component={Discharge} />
          <Route path="/Prescription" component={Prescription} />
          <Route path="/AssignDoctor" component={AssignDoctor} />
          <Route path="/CreateAppointment" component={CreateAppointment} />
          <Route path="/RegisterNewPatient" component={RegisterNewPatient} />
          <Route path="/RegisterExistingPatient" component={RegisterExistingPatient} />
          <Route path="/BillingAndPayments" component={BillingAndPayments} />
          <Route path="/Receipts" component={Receipts}/>

          {/* Calendar */}
          <Route path="/ViewAllAppointments" component={ViewAllAppointments} />
          <Route path="/ViewDocCalendars" component={ViewDocCalendars} />
          <Route path="/ViewDocAppointments" component={ViewDocAppointments} />

          {/* Misc */}
          <Route path="/ViewReports" component={ViewReports} />
          <Route path="/ViewDashboard" component={ViewDashboard} />
          <Route path="/SearchPatients" component={SearchPatients} />
          <Route path="/ViewPolicies" component={ViewPolicies} />

          {/* Staff Admin */}
          <Route path="/CreateNewStaff" component={CreateNewStaff} />
          <Route path="/RosterTime" component={RosterTime} />
          <Route path="/EditStaffInfo" component={EditStaffInfo} />
          <Route path="/StaffHolidays" component={StaffHolidays} />

          {/* Patient Admin */}
          <Route path="/EditPatientTypes" component={EditPatientTypes} />

          {/* Template Admin */}
          <Route path="/EditEmailTemplates" component={EditEmailTemplates} />
          <Route
            path="/EditReferralTemplates"
            component={EditReferralTemplates}
          />

          {/* Treatment Admin */}
          <Route path="/EditTreatments" component={EditTreatments} />

          {/* Appointment Admin */}
          <Route
            path="/EditAppointmentTypes"
            component={EditAppointmentTypes}
          />

          {/* Access Control */}
          <Route path="/AccessControl" component={AccessControl} />

          {/* 404 route */}
          <Route path="/" component={NotFound404} />
        </Switch>
      </Router>
    </>
  );
}
