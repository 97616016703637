import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/r1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/r2.png";

export default function Receipts() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Receipts</h1>

          <ol className="listTop">
            <li>Click on the “Payments” on the left-hand side of the screen</li>
            <img src={img1} alt="not found" width="80%" height="80%" />
            <br />
            <Divider />
            <li>
            Click the print button: 
            </li>
            <img src={img2} alt="not found" width="80%" height="80%" />
          </ol>
        </div>
      </BrowserView>

      <MobileView>
        <h1 className="mobileTitle">Receipts</h1>

        <ol className="listTop">
        <li>Click on the “Payments” on the left-hand side of the screen</li>
            <img src={img1} alt="not found" width="80%" height="80%" />
            <br />
            <Divider />
            <li>
            Click the print button: 
            </li>
            <img src={img2} alt="not found" width="80%" height="80%" />
        </ol>
      </MobileView>
    </>
  );
}
