import React from "react";
import Divider from "@material-ui/core/Divider";
import SearchSection from "./components/search";
import { BrowserView, MobileView } from "react-device-detect";


//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/Artp1.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/Artp2.png";
const img4 = "https://mspublicaccess.blob.core.windows.net/faqimages/Artp3.png";
const img5 = "https://mspublicaccess.blob.core.windows.net/faqimages/Artp4.png";

const image_cn1 = "https://mspublicaccess.blob.core.windows.net/faqimages/vcn1.png";
const image_cn2 = "https://mspublicaccess.blob.core.windows.net/faqimages/clinicalnoteletter.png";


export default function AddReftoPatient() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Add Referral to Patient</h1>
          <>
            <ul>There are two methods for adding a referral letter to a patient, one has explicitly selected
              clinical notes and the other implicitly extracts them from the currently active appointment, if known.</ul>
            <br />
            <Divider />
          </>
          <ol className="listTop">
            Normally the way to access a patient is through the context of their currently active appointment
            by clicking on the patient name the Active Patients dashboard.
            <br/>
            If you instead access the patient by performing a patient search, then there will be no appointment context, resulting in an empty Clinical
            Notes form-field on the Referral Letter form.

            <h2>Referral with Explicitly Selected Clinical Notes</h2>
            Use this method if you are searching for a patient rather than selected the patient from the
            active patients dashboard i.e. you're writing a referral letter based on an appointment from a
            few days ago.

            <SearchSection />
            <br />
            <Divider />
            <li>Click on the patient to open their dashboard and click the “Clinical Notes” button:</li>
            <img src={image_cn1} alt="not found" />
            <li>Click on “New Letter” button for the clinical note that you wish to create a referral letter for.
            This action will move you to the referral letter page, populating the Clinical Notes section with the
            content of the note.</li>
            <img src={image_cn2} alt="not found" width="90%" />
            <li>Select the date, Letter Type and Practitioner for the form, all the other information is brought
              in automatically from the patient's file:</li>
          <img src={img4} alt="not found" width="90%" />
          <li>Save the referral, and then choose the required action such as 'Print': </li>
          <img src={img5} alt="not found" />
          </ol>
          <br />
          <Divider />

          <ol className="listTop">
            <h2>Referral with Implicit Clinical Notes</h2>
            This is an alternative method for creating a referral letter which works best if you have accessed
            the patient record by selecting the patient from the active patients list.

            <li>Click on the “Referral” button:</li>
            <img src={img2} alt="not found" />
            <li>Click on “+ New Referral” on the top right.</li>
            <img src={img3} alt="not found" width="90%" />
            <li>Select the date, Letter Type and Practitioner for the form, all the other information is brought in
              automatically from the patient's file.  If the Clinical Notes section is empty, and you are expecting
              content there, then you must first view the Clinical Notes and select <i>New Letter</i> to return here
              with the note-content filled in.</li>
            <img src={img4} alt="not found" width="90%" />
            <li>Save the referral, and then choose the required action such as 'Print': </li>
            <img src={img5} alt="not found" />
          </ol>
        </div>
      </BrowserView>
      <MobileView>
        <h1 className="mobileTitle">Add Referral to Patient</h1>
        <ol className="listTop">
          <SearchSection />
          <br />
          <Divider />
          <li>Click on the “Referral” button:</li>
          <img src={img2} alt="not found" />
          <li>Click on “+ New Referral” on the top right</li>
          <img src={img3} alt="not found" width="90%" />
          <li>Select the date, Letter Type and Practitioner for the form, all the other information is brought in automatically fromt he patient's file:</li>
          <img src={img4} alt="not found" width="90%" />
          <li>Save the referral, and then choose the required action such as 'Print': </li>
          <img src={img5} alt="not found" />
        </ol>
      </MobileView>
    </>
  );
}
