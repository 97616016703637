import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/vaa.png";

export default function ViewAllAppointments() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
     
     <h1 className="browserTitle">View All Appointments</h1>
     <ol className="listTop">
       <li>Sign in a go to your Dashboard</li>
       <img src={img1} alt="not found" width="80%" height="80%" />
       <br />
       <Divider />
       <li>All Patients that are assigned to you (Doctor) or are scheduled for the day (Nurse/Reception) are listed here</li>
       
     </ol>
   </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">View All Appointments</h1>
     <ol className="listTop">
       <li>Sign in a go to your Dashboard</li>
       <img src={img1} alt="not found" width="80%" height="80%" />
       <br />
       <Divider />
       <li>All Patients that are assigned to you (Doctor) or are scheduled for the day (Nurse/Reception) are listed here</li>
       
     </ol>
    </MobileView>
    </>
  
  );
}
