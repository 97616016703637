import React from "react";
import SearchSection from "./components/search";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/sp1.png";

export default function SearchPatients() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      
      <h1 className="browserTitle">Search Patients</h1>
      <ol className="listTop">
       <SearchSection />
        <li>
          Click on the user and select the pencil button if you want to make any
          changes to the user
        </li>
        <img src={img2} alt="not found" />
        <li>
          Make your changes and save them by clicking the “Save” button at the
          bottom of the page.
        </li>
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Search Patients</h1>
      <ol className="listTop">
       <SearchSection />
        <li>
          Click on the user and select the pencil button if you want to make any
          changes to the user
        </li>
        <img src={img2} alt="not found" />
        <li>
          Make your changes and save them by clicking the “Save” button at the
          bottom of the page.
        </li>
      </ol>
    </MobileView>
    </>
   
  );
}
