import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/ert1.png";


export default function EditReferralTemplates() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      <h1 className="browserTitle">Edit/Add Referral Templates</h1>
      <ol className="listTop">
        <li>On the navigation menu on the left-hand side of the page, click on the “Admin Panel” dropdown and select “Referral Templates” </li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />

        <li>You can edit Existing templates by clicking on the highlighted edit button shown above.</li>
        
        <li>Or you can add a new template by clicking the green “Add Email Template” button on the top right. </li>
        <li>You can also view or delete the templates using the corresponding buttons too. </li>
       
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Edit/Add Referral Templates</h1>
      <ol className="listTop">
        <li>On the navigation menu on the left-hand side of the page, click on the “Admin Panel” dropdown and select “Referral Templates” </li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />

        <li>You can edit Existing templates by clicking on the highlighted edit button shown above.</li>
        
        <li>Or you can add a new template by clicking the green “Add Email Template” button on the top right. </li>
        <li>You can also view or delete the templates using the corresponding buttons too. </li>
       
      </ol>
    </MobileView>
    </>

  );
}