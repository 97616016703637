import React from "react";
import Divider from "@material-ui/core/Divider";
import SearchSection from "./components/search";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";


//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/agn1.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/agn2.png";
const img4 = "https://mspublicaccess.blob.core.windows.net/faqimages/agn3.png";


export default function AddGeneralNotes() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      
      <h1 className="browserTitle">Add General Notes</h1>
      <ol className="listTop">
        <SearchSection />
        <br/>
        <Divider />
        <li>Click on the General Notes button:</li>
        <img src={img2} alt="not found" width="80%" height="80%" />
        <li>Click on the text box the note. Then hit the “Save Note” button. Then you can click the 'Sticky' button to show it on the patient's dashboard.</li>
        <img src={img3} alt="not found" />
        <li>Go to the patient’s dashboard to view the notes</li>
        <img src={img4} alt="not found" width="80%" height="80%" />

      </ol>
    </div>
    </BrowserView>
    <MobileView>
    
    <h1 className="mobileTitle">Add General Notes</h1>
      <ol className="listTop">
        <SearchSection />
        <br/>
        <Divider />
        <li>Click on the General Notes button:</li>
        <img src={img2} alt="not found" width="80%" height="80%" />
        <li>Click on the text box the note. Then hit the “Save Note” button. Then you can click the 'Sticky' button to show it on the patient's dashboard.</li>
        <img src={img3} alt="not found" />
        <li>Go to the patient’s dashboard to view the notes</li>
        <img src={img4} alt="not found" width="80%" height="80%" />
      </ol>
    </MobileView>
    </>
    
  );
}
