import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/cp1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/ap1.png";

export default function AdmitPatient() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
     <h1 className="browserTitle">AdmitPatient</h1>
     <ol className="listTop">
       <li>Sign in a go to your Dashboard.</li>
       <img src={img1} alt="not found" width="80%" height="80%" />
       <br />
       <Divider />
       <li>
         When the patient has arrived at the practice – you can admit/check
         them in with one click on “Admit Patient” button.
       </li>
       <img src={img2} alt="not found" width="80%" height="80%"/>
     </ol>
   </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">AdmitPatient</h1>
     <ol className="listTop">
       <li>Sign in a go to your Dashboard.</li>
       <img src={img1} alt="not found" width="80%" height="80%" />
       <br />
       <Divider />
       <li>
         When the patient has arrived at the practice – you can admit/check
         them in with one click on “Admit Patient” button.
       </li>
       <img src={img2} alt="not found" width="80%" height="80%"/>
     </ol>
    </MobileView>
    </>
   
  );
}
