import React from "react";
import { isBrowser } from "react-device-detect";
import Sidebar from "react-sidebar";
import DoctorsList from "./DoctorsList";
import NurseList from "./NurseList";
import AdminList from "./AdminList";
import ReceptionList from "./ReceptionList";
import Logo from "./UI/Logo/Logo";
//burger menu
import { MobileView } from "react-device-detect";


class FaqNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: true,
      docked: true,
      defaultSidebarWidth: 900,
      touch: true,
      touchHandleWidth: 40,
      dragToggleDistance: 50,
      rootClassName: "root",
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  onSetSidebarOpen(open) {
    if (isBrowser) {
      this.setState({
        sidebarOpen: open,
        docked: true,
        defaultSidebarWidth: 900,
        touch: true,
        touchHandleWidth: 40,
        dragToggleDistance: 50,
        rootClassName: "root",
      });
    }
    this.setState({
      sidebarOpen: open,
      docked: false,
      defaultSidebarWidth: 900,
      touch: true,
      touchHandleWidth: 150,
      dragToggleDistance: 50,
      rootClassName: "root",
    });
  }

  render() {
    return (
      <div className="fixed">
        <Sidebar
          sidebar={
            <div className="sidediv">
              <div className="bg-white">
                <Logo></Logo>
              </div>
              {/* Doctor List */}
              <DoctorsList />
              {/* Nurse List */}
              <NurseList />
              {/* Reception List */}
              <ReceptionList />
              {/* Admin List */}
              <AdminList />
            </div>
          }
          open={this.state.sidebarOpen}
          docked={this.state.docked}
          onSetOpen={this.onSetSidebarOpen}
          defaultSidebarWidth={this.state.defaultSidebarWidth}
          touch={this.state.touch}
          touchHandleWidth={this.state.touchHandleWidth}
          dragToggleDistance={this.state.dragToggleDistance}
          rootClassName={this.state.rootClassName}
          styles={{
            sidebar: {
              background: "#1b1e24",
              width: "300px",
              color: "white",
              zIndex: 10,
            },
            overlay: {
              zIndex: 1,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0,
              visibility: "hidden",
              transition: "opacity .3s ease-out, visibility .3s ease-out",
              backgroundColor: "rgba(0,0,0,.3)",
            },
            dragHandle: {
              zIndex: 1,
              position: "fixed",
              top: 0,
              bottom: 0,
            },
            root: {
              position: "fixed",
            },
          }}
        >
          <MobileView>
          <button className="burgerbtn" onClick={() => this.onSetSidebarOpen(true)}>
            <div/><div/><div/>
          </button>
          </MobileView>
        </Sidebar>
      </div>
    );
  }
}

export default FaqNav;
