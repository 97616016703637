import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";




export default function NurseVideo() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Nurse Process Video</h1>
          <div className="videoContainer">
          <LiteYouTubeEmbed
              id="4YMzKcVXVs0"
              title="MedoSync Billing"
            />
          </div>
        </div>
      </BrowserView>
      <MobileView>
      <div className="spacebottom">
          <h1 className="mobileTitle">Nurse Process Video</h1>
          <div className="videoContainer">
          <LiteYouTubeEmbed
              id="4YMzKcVXVs0"
              title="MedoSync Billing"
            />
          </div>
        </div>
      </MobileView>
    </>
  );
}
