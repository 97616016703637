import React from "react";

import {
  BrowserView,
  MobileView,
} from "react-device-detect";


//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/mp2.png";
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/mp1.png";



export default function MergePatient() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      
      <h1 className="browserTitle">Merge Patients</h1>
      <ol className="listTop">
        <br/>
        <li>You can merge a patient by using the following link:</li>
        <li><a href="https://prod.affidea.medosync.com/patients?lastname=Jones&firstname=John"> https://prod.affidea.medosync.com/patients?lastname=Jones&firstname=John</a></li>
        <li>Replace the firstname and lastname in the link to the patient you are searching for</li>
        <img src={img1} alt="not found" width="80%" height="80%" />
        <li>Click the checkboxes and then click on merge</li>
        <img src={img2} alt="not found" width="80%" height="80%" />

      </ol>
    </div>
    </BrowserView>
    <MobileView>
    
    <h1 className="browserTitle">Merge Patients</h1>
      <ol className="listTop">
        <br/>
        <li>You can merge a patient by using the following link:</li>
        <li><a href="https://prod.affidea.medosync.com/patients?lastname=Jones&firstname=John"> https://prod.affidea.medosync.com/patients?lastname=Jones&firstname=John</a></li>
        <li>Replace the firstname and lastname in the link to the patient you are searching for</li>
        <img src={img1} alt="not found" width="80%" height="80%" />
        <li>Click the checkboxes and then click on merge</li>
        <img src={img2} alt="not found" width="80%" height="80%" />

      </ol>
    </MobileView>
    </>
    
  );
}
