import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/rt1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/rt2.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/rt3.png";

export default function RosterTime() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Roster Hours - Time Off</h1>
          <ol className="listTop">
            <li>
              On the navigation menu on the left-hand side of the page, click on
              the “Admin Panel” dropdown and select “Staff”
            </li>

            <br />
            <Divider />

            <li>Then click on the “Roster Hour/ Time-off” button </li>
            <img src={img1} alt="not found" width="80%" height="80%" />

            <li>Fill out the required info and click “Save”</li>
            <img src={img2} alt="not found" width="90%" height="90%" />
            <ol type="a">
              <li>
                In this above picture I have it set that Esther will work
                Wednesday &amp; Thursday mornings from 9am to 12pm and this will
                repeat on 3 weeks that I have selected.{" "}
              </li>
            </ol>

            <li>
              To set time-off select it from the drop down shown below and fill
              in the dates and reason.
            </li>
            <img src={img3} alt="not found" width="90%" height="90%" />
            <ol type="a">
              <li>
                In this example Esther will be off on Friday for Holidays on the
                15th of January{" "}
              </li>
            </ol>
          </ol>
        </div>
      </BrowserView>
      <MobileView>
        <h1 className="mobileTitle">Roster Hours - Time Off</h1>
        <ol className="listTop">
          <li>
            On the navigation menu on the left-hand side of the page, click on
            the “Admin Panel” dropdown and select “Staff”
          </li>

          <br />
          <Divider />

          <li>Then click on the “Roster Hour/ Time-off” button </li>
          <img src={img1} alt="not found" width="80%" height="80%" />

          <li>Fill out the required info and click “Save”</li>
          <img src={img2} alt="not found" width="90%" height="90%" />
          <ol type="a">
            <li>
              In this above picture I have it set that Esther will work
              Wednesday &amp; Thursday mornings from 9am to 12pm and this will
              repeat on 3 weeks that I have selected.{" "}
            </li>
          </ol>

          <li>
            To set time-off select it from the drop down shown below and fill in
            the dates and reason.
          </li>
          <img src={img3} alt="not found" width="90%" height="90%" />
          <ol type="a">
            <li>
              In this example Esther will be off on Friday for Holidays on the
              15th of January{" "}
            </li>
          </ol>
        </ol>
      </MobileView>
    </>
  );
}
