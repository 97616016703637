import React from "react";
import SearchSection from "./components/search";
import { BrowserView, MobileView } from "react-device-detect";


//images

const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/Apf1.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/Apf2.png";

export default function AddPatientFiles() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Add Patient Files</h1>

          <ol className="listTop">
            <SearchSection />
            <li>
              Click on the patient when you find them to open their dashboard
              and click the “Patient Files” button:
            </li>
            <img src={img2} alt="not found" width="80%" height="80%" />
            <li>
              Choose from the options shown, Drag and drop a file or click on a
              button to add a file:
            </li>
            <img src={img3} alt="not found" width="90%" height="90%" />
          </ol>
        </div>
      </BrowserView>
      <MobileView>
        <h1 className="mobileTitle">Add Patient Files</h1>

        <ol className="listTop">
          <SearchSection />
          <li>
            Click on the patient when you find them to open their dashboard and
            click the “Patient Files” button:
          </li>
          <img src={img2} alt="not found" width="80%" height="80%" />
          <li>
            Choose from the options shown, Drag and drop a file or click on a
            button to add a file:
          </li>
          <img src={img3} alt="not found" width="90%" height="90%" />
        </ol>
      </MobileView>
    </>
  );
}
