import React from 'react';
import { Link } from 'react-router-dom';

import Img from '../../../assets/images/logo.png';

export default function Logo() {
	return (
		<Link to="/">
			<img src={Img} alt="" />
		</Link>
	);
}
