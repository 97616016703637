import React from "react";
import Divider from "@material-ui/core/Divider";
import {
  BrowserView,
  MobileView,
  TabletView,

} from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/ac1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/ac2.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/ac3.png";

export default function AccessControl() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      <h1 className="browserTitle">Access Control</h1>
      <ol className="listTop">
        <li>
          To open the access control window, click on the “Admin Panel” on the
          left hand side of the screen, then scroll down and click on “Access
          Control”:
        </li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />

        <li>
          Select the role you wish to edit which is indicated by yellow
          highlighted box:
        </li>
        <img src={img2} alt="not found" width="80%" height="80%"/>

        <li>
          Select the component you wish to grant or remove access to. In this
          case I have granted access to the “billing” tab for the doctor in the
          Patients Dashboard.
        </li>
        <img src={img3} alt="not found"  width="90%" height="90%"/>

        <li>
          Scroll to the bottom of the page and click the green “Save” button to
          save changes.
        </li>
      </ol>
    </div>
    </BrowserView>
    <MobileView>
      <h1 className="mobileTitle">Access Control</h1>
      <ol className="listTop">
        <li>
          To open the access control window, click on the “Admin Panel” on the
          left hand side of the screen, then scroll down and click on “Access
          Control”:
        </li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />

        <li>
          Select the role you wish to edit which is indicated by yellow
          highlighted box:
        </li>
        <img src={img2} alt="not found" />

        <li>
          Select the component you wish to grant or remove access to. In this
          case I have granted access to the “billing” tab for the doctor in the
          Patients Dashboard.
        </li>
        <img src={img3} alt="not found"  width="90%" height="90%"/>

        <li>
          Scroll to the bottom of the page and click the green “Save” button to
          save changes.
        </li>
      </ol>
  
    </MobileView>
    <TabletView>
    <h1 className="mobileTitle">Access Control</h1>
      <ol className="listTop">
        <li>
          To open the access control window, click on the “Admin Panel” on the
          left hand side of the screen, then scroll down and click on “Access
          Control”:
        </li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />

        <li>
          Select the role you wish to edit which is indicated by yellow
          highlighted box:
        </li>
        <img src={img2} alt="not found" />

        <li>
          Select the component you wish to grant or remove access to. In this
          case I have granted access to the “billing” tab for the doctor in the
          Patients Dashboard.
        </li>
        <img src={img3} alt="not found"  width="90%" height="90%"/>

        <li>
          Scroll to the bottom of the page and click the green “Save” button to
          save changes.
        </li>
      </ol>
  
    </TabletView>
    </>
    
  );
}
