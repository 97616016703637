import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/vda1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/vda2.png";


export default function ViewDocAppointments() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      
      <h1 className="browserTitle">View Doctor's Appointments</h1>
      <ol className="listTop">
        <li>
          Click on the Appointment icon in the navigation menu on the left
        </li>
        <img src={img1} alt="not found" width="20%" height="100%" />
        <br />
        <Divider />
        <li>Select the doctor’s name and choose from the actions column to manage the appointment:</li>
        <img src={img2} alt="not found" width="80%" height="80%"  />
        
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">View Doctor's Appointments</h1>
      <ol className="listTop">
        <li>
          Click on the Appointment icon in the navigation menu on the left
        </li>
        <img src={img1} alt="not found" width="20%" height="40%" />
        <br />
        <Divider />
        <li>Select the doctor’s name and choose from the actions column to manage the appointment:</li>
        <img src={img2} alt="not found" width="80%" height="80%"  />
        
      </ol>
    </MobileView>
    </>
   
  );
}
