import React from "react";
import Divider from "@material-ui/core/Divider";
import SearchSection from "./components/search";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/bap1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/bap2.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/bap3.png";
const img4 = "https://mspublicaccess.blob.core.windows.net/faqimages/bap4.png";

export default function BillingAndPayments() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Billing And Payments</h1>

          <ol className="listTop">
          <SearchSection />
            <li>Click on the “Payment” button</li>
            <img src={img1} alt="not found" width="80%" height="80%" />
            <br />
            <Divider />
            <li>
              Now you will be redirected to the MedoSync billing platform, where
              you will need to fill out the MRN number, select the patient’s
              policy from the drop down and the status of the policy.
            </li>
            <img src={img2} alt="not found" width="80%" height="80%" />
            <li>
              Make sure that the treatments are correct – they can be edited by
              click the “Edit Treatments” button:
            </li>
            <img src={img3} alt="not found" width="80%" height="80%" />
            <br />
            <Divider />
            <li>Then submit the invoice, you will need to select the drop-downs for payment method and payment received, and click submit.</li>
            <img src={img4} alt="not found" width="20%"/>
          </ol>
        </div>
      </BrowserView>

      <MobileView>
        <h1 className="mobileTitle">Assign Doctor</h1>

        <ol className="listTop">
        <SearchSection />
        <li>Click on the “Payment” button</li>
            <img src={img1} alt="not found" width="80%" height="80%" />
            <br />
            <Divider />
            <li>
              Now you will be redirected to the MedoSync billing platform, where
              you will need to fill out the MRN number, select the patient’s
              policy from the drop down and the status of the policy.
            </li>
            <img src={img2} alt="not found" width="80%" height="80%" />
            <li>
              Make sure that the treatments are correct – they can be edited by
              click the “Edit Treatments” button:
            </li>
            <img src={img3} alt="not found" width="80%" height="80%" />
            <br />
            <Divider />
            <li>Then submit the invoice, you will need to select the drop-downs for payment method and payment received, and click submit.</li>
            <img src={img4} alt="not found" width="80%" height="80%" />
        </ol>
      </MobileView>
    </>
  );
}
