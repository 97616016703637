import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/vd1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/vd2.png";

export default function ViewDashboard() {
  return ( <>
    <BrowserView>
    <div className="spacebottom">
      
      <h1 className="browserTitle">View Dashboard</h1>
      <ol className="listTop">
        <li>
          Go to the My Medical Landing Page :-
          https://mymedical.tactionsoft.com/signin
        </li>
        <img src={img1} alt="not found" />
        <li>Once you log in you will land on your dashboard</li>
        <img src={img2} alt="not found" width="80%" height="80%"/>
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">View Dashboard</h1>
      <ol className="listTop">
        <li>
          Go to the My Medical Landing Page :-
          https://mymedical.tactionsoft.com/signin
        </li>
        <img src={img1} alt="not found" />
        <li>Once you log in you will land on your dashboard</li>
        <img src={img2} alt="not found" width="80%" height="80%"/>
      </ol>
    </MobileView>
    </>

  
  );
}
