import React from "react";
import Divider from "@material-ui/core/Divider";
import SearchSection from "./components/search";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/vpf1.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/vpf2.png";


export default function ViewPatientForms() {
  return ( <>
    <BrowserView>
    <div className="spacebottom">
      
    <h1 className="browserTitle">View Patient Forms</h1>
      <ol className="listTop">
        <SearchSection />
        <br/>
        <Divider />
        <li>Click on the patient to open their dashboard, then click the “Patient Forms” button:</li>
        <img src={img2} alt="not found" />
        <li>Click on the view or print action buttons </li>
        <img src={img3} alt="not found" width="80%" height="80%"/>
        
      </ol>
    </div>
    </BrowserView>
    <MobileView>
 
    <h1 className="mobileTitle">View Patient Forms</h1>
      <ol className="listTop">
        <SearchSection />
        <br/>
        <Divider />
        <li>Click on the patient to open their dashboard, then click the “Patient Forms” button:</li>
        <img src={img2} alt="not found" />
        <li>Click on the view or print action buttons </li>
        <img src={img3} alt="not found" width="80%" height="80%"/>
        
      </ol>
    </MobileView>
    </>

    
  );
}
