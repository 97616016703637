import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/cns1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/cns2.png";


export default function CreateNewStaff() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
    <h1 className="browserTitle">Create New Staff</h1>
      <ol className="listTop">
        <li>
        On the navigation menu on the left-hand side of the page, click on the “Admin Panel” dropdown and select “New Staff”
        </li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />

        <li>Fill out the new staff members details in the given boxes:</li>
        <ol type="a">
            <li> Use the drop-down menus to select their Title, Role, colour code and practice location. </li>
            <li> Click on the “generate” button to generate a random password for the users first log on.</li>
          </ol>
        <img src={img2} alt="not found"  width="80%" height="80%"/>

        
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Create New Staff</h1>
      <ol className="listTop">
        <li>
        On the navigation menu on the left-hand side of the page, click on the “Admin Panel” dropdown and select “New Staff”
        </li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />

        <li>Fill out the new staff members details in the given boxes:</li>
        <ol type="a">
            <li> Use the drop-down menus to select their Title, Role, colour code and practice location. </li>
            <li> Click on the “generate” button to generate a random password for the users first log on.</li>
          </ol>
        <img src={img2} alt="not found"  width="80%" height="80%"/>

        
      </ol>
    </MobileView>
    </>
   
  );
}
