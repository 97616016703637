import React from "react";
import SearchSection from "./components/search";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/pres1.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/pres2.png";
const img4 = "https://mspublicaccess.blob.core.windows.net/faqimages/pres3.png";
const img5 = "https://mspublicaccess.blob.core.windows.net/faqimages/pres4.png";

export default function Prescription() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      <h1 className="browserTitle">Add Prescription to Patient</h1>
      <ol className="listTop">
        <SearchSection />
        <li>
          Click on the prescriptions button in the dropdown of the patient’ menu
          and then click on “+ Add Prescription”.
        </li>
        <img src={img2} alt="not found" width="80%" height="80%" />
        <li>
        Click on “Add Drug”, Fill in the information on the Dosage etc.
        <ol type="a">
          <li> Click on “Add Drug” to add another drug. </li>
          <li> Click on the red “X” to remove a drug from the prescription.</li>
          <li> Click on “Save Prescription” to proceed to the next step.</li>
        </ol>
      </li>
      <img src={img3} alt="not found" width="80%" height="80%" />
      <li>Click on the blue “PDF” button to view the prescription and print it</li>
      <img src={img4} alt="not found" width="80%" height="80%" />
      <img src={img5} alt="not found" width="80%" height="80%" />
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Add Prescription to Patient</h1>
      <ol className="listTop">
        <SearchSection />
        <li>
          Click on the prescriptions button in the dropdown of the patient’ menu
          and then click on “+ Add Prescription”.
        </li>
        <img src={img2} alt="not found" width="80%" height="80%" />
        <li>
        Click on “Add Drug”, Fill in the information on the Dosage etc.
        <ol type="a">
          <li> Click on “Add Drug” to add another drug. </li>
          <li> Click on the red “X” to remove a drug from the prescription.</li>
          <li> Click on “Save Prescription” to proceed to the next step.</li>
        </ol>
      </li>
      <img src={img3} alt="not found" width="80%" height="80%" />
      <li>Click on the blue “PDF” button to view the prescription and print it</li>
      <img src={img4} alt="not found" width="80%" height="80%" />
      <img src={img5} alt="not found" width="80%" height="80%" />
      </ol>
    </MobileView>
    </>
  
  );
}
