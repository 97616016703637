import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";
//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/ept1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/ept2.png";

export default function EditPatientTypes() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      <h1 className="browserTitle">Edit Patient Types</h1>
      <ol className="listTop">
        <li>On the navigation menu on the left-hand side of the page, click on the “Admin Panel” dropdown and select “Patient Type”</li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />
        
        <li>Click edit to change an already existing type or add a new type by click the green button on the top right.</li>
        <li>Enter the information and click “Save” </li>
        <img src={img2} alt="not found" />

       
      </ol>
    </div>
    </BrowserView>
    <MobileView>
    <h1 className="mobileTitle">Edit Patient Types</h1>
      <ol className="listTop">
        <li>On the navigation menu on the left-hand side of the page, click on the “Admin Panel” dropdown and select “Patient Type”</li>

        <img src={img1} alt="not found" width="80%" height="80%" />

        <br />
        <Divider />
        
        <li>Click edit to change an already existing type or add a new type by click the green button on the top right.</li>
        <li>Enter the information and click “Save” </li>
        <img src={img2} alt="not found" />

       
      </ol>
    </MobileView>
    </>
    
  );
}