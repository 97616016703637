import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/vdc1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/vdc2.png";



export default function ViewDocCalendars() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      
      <h1 className="browserTitle">View Doctor's Calendars</h1>
      <ol className="listTop">
        <li>
        Click on the Calendar icon in the navigation menu on the left
        </li>
        <img src={img1} alt="not found" width="70%" height="80%"/>
        <br/>
        <Divider />
        <li>Select the doctor’s calendars that you wish to view by clicking the tick box next to their name:</li>
        <img src={img2} alt="not found" />
        <li>Each appointment will be shown in the calendar on your screen.</li>
        
      </ol>
    </div>
    </BrowserView>
    <MobileView>
 
    <h1 className="mobileTitle">View Doctor's Calendars</h1>
      <ol className="listTop">
        <li>
        Click on the Calendar icon in the navigation menu on the left
        </li>
        <img src={img1} alt="not found" width="70%" height="80%"/>
        <br/>
        <Divider />
        <li>Select the doctor’s calendars that you wish to view by clicking the tick box next to their name:</li>
        <img src={img2} alt="not found" />
        <li>Each appointment will be shown in the calendar on your screen.</li>
        
      </ol>
    </MobileView>
    </>
   
  );
}
