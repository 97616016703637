import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";


export default function DoctorVideo() {
  return (
    <>
      <BrowserView>
        <div className="spacebottom">
          <h1 className="browserTitle">Doctor Process Video</h1>
          <div className="videoContainer">
            <LiteYouTubeEmbed id="eN9YrRYqyqY" title="MedoSync Billing" />
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className="spacebottom">
          <h1 className="mobileTitle">Doctor Process Video</h1>
          <div className="videoContainer">
            <LiteYouTubeEmbed id="eN9YrRYqyqY" title="MedoSync Billing" />
          </div>
        </div>
      </MobileView>
    </>
  );
}
