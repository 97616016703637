import React from "react";

//images

const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/Epi1.png";

export default function SearchSection() {
  return (
    <>
      <li>
        Find the patient you wish to edit, you can search the patients name, date of birth or phone number, to do this you can either:
        <ol type="a">
          <li> Click on the patient in your dashboard</li>
          <li> Use the search bar on the main screen </li>
          <li> Use the search bar on the dashboard</li>
          <li> Scroll through the patients list located in the side menu</li>
        </ol>
      </li>
      <img src={img1} alt="not found" width="80%" height="80%" />
    </>
  );
}
