import React from "react";
import Divider from "@material-ui/core/Divider";
import { BrowserView, MobileView } from "react-device-detect";

//images
const img1 = "https://mspublicaccess.blob.core.windows.net/faqimages/cp1.png";
const img2 = "https://mspublicaccess.blob.core.windows.net/faqimages/dp1.png";
const img3 = "https://mspublicaccess.blob.core.windows.net/faqimages/dp2.png";

export default function Discharge() {
  return (
    <>
    <BrowserView>
    <div className="spacebottom">
      

      <h1 className="browserTitle">Discharge Patient</h1>
      <ol className="listTop">
        <li>Sign in a go to your Dashboard.</li>
        <img src={img1} alt="not found" width="80%" height="80%" />
        <br />
        <Divider />
        <li>
        When the patient is ready to be Discharged you can select the “Discharge” button.
        </li>
        <img src={img2} alt="not found" width="90%" height="90%"/>
        <li>
        You can then assign them an advice sheet if required and click the “Send” button at the bottom
        </li>
        <img src={img3} alt="not found" />
      </ol>
    </div>
    </BrowserView>
    <MobileView>
 

    <h1 className="mobileTitle">Discharge Patient</h1>
      <ol className="listTop">
        <li>Sign in a go to your Dashboard.</li>
        <img src={img1} alt="not found" width="80%" height="80%" />
        <br />
        <Divider />
        <li>
        When the patient is ready to be Discharged you can select the “Discharge” button.
        </li>
        <img src={img2} alt="not found" width="90%" height="90%"/>
        <li>
        You can then assign them an advice sheet if required and click the “Send” button at the bottom
        </li>
        <img src={img3} alt="not found" />
      </ol>
    </MobileView>
    </>
    
  );
}
